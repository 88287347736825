import { Brand } from 'ultimate-league-common';

import { IColorPalettes, TColorValue } from '../../../theme';
import { IProgressProps } from '../../feedback';
import type { IButtonProps } from './Button';

type IButtonColorStatesConfig = Record<
  'default' | 'disabled' | 'hover' | 'pressed' | 'focused' | 'loading',
  {
    text: TColorValue;
    border?: TColorValue;
    background?: TColorValue;
  }
>;

export const getLoadingColor = ({
  color,
  variant,
}: Pick<
  Required<IButtonProps>,
  'variant' | 'color'
>): Required<IProgressProps>['color'] =>
  ((
    {
      default: {
        filled: 'dark',
        tonal: 'default',
        outlined: 'default',
        text: 'default',
      },
      dark: {
        filled: 'default',
        tonal: 'dark',
        outlined: 'dark',
        text: 'dark',
      },
      accent: {
        filled: 'dark',
        tonal: 'accent',
        outlined: 'accent',
        text: 'accent',
      },
      error: {
        filled: 'default',
        tonal: 'error',
        outlined: 'error',
        text: 'error',
      },
      warning: {
        filled: 'default',
        tonal: 'warning',
        outlined: 'warning',
        text: 'warning',
      },
      success: {
        filled: 'default',
        tonal: 'success',
        outlined: 'success',
        text: 'success',
      },
      info: {
        filled: 'default',
        tonal: 'info',
        outlined: 'info',
        text: 'info',
      },
      virtual: {
        filled: 'default',
        tonal: 'virtual',
        outlined: 'virtual',
        text: 'virtual',
      },
    } as Record<
      Required<IButtonProps>['color'],
      Record<
        Required<IButtonProps>['variant'],
        Required<IProgressProps>['color']
      >
    >
  )[color][variant]);

/**
 * Translate IButtonProps (variant and color) to actual text, background and border color
 */
export const computeColors = (
  colors: IColorPalettes
): Record<
  Required<IButtonProps>['color'],
  Record<Required<IButtonProps>['variant'], IButtonColorStatesConfig>
> => ({
  default: {
    filled: {
      default: {
        text: colors.primary['100'],
        background: colors.primary['0'],
      },
      hover: {
        text: colors.primary['100'],
        background: colors.primary['5'],
      },
      focused: {
        text: colors.primary['100'],
        background: colors.primary['5'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: colors.primary['100'],
        background: colors.primary['10'],
      },
      loading: {
        text: colors.primary['100'],
        background: colors.primary['5'],
      },
    },
    tonal: {
      default: {
        text: colors.primary['0'],
        background: colors.primary['Light 5%'],
      },
      hover: {
        text: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      focused: {
        text: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: colors.primary['0'],
        background: colors.primary['Light 20%'],
      },
      loading: {
        text: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
    },
    outlined: {
      default: {
        text: colors.primary['0'],
        border: colors.primary['Light 5%'],
      },
      hover: {
        text: colors.primary['0'],
        border: colors.primary['Light 10%'],
      },
      focused: {
        text: colors.primary['0'],
        background: colors.primary['Light 5%'],
        border: colors.primary['Light 10%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
      pressed: {
        text: colors.primary['0'],
        background: colors.primary['Light 5%'],
        border: colors.primary['Light 10%'],
      },
      loading: {
        text: colors.primary['0'],
        border: colors.primary['Light 30%'],
      },
    },
    text: {
      default: {
        text: colors.primary['0'],
      },
      hover: {
        text: colors.primary['0'],
        background: colors.primary['Light 5%'],
      },
      focused: {
        text: colors.primary['0'],
        background: colors.primary['Light 5%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
      pressed: {
        text: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      loading: {
        text: colors.primary['0'],
        background: colors.primary['Light 5%'],
      },
    },
  },
  dark: {
    filled: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['100'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['90'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['90'],
      },
      disabled: {
        text: colors.primary['75'],
        background: colors.primary['90'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['85'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['90'],
      },
    },
    outlined: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        border: colors.primary['Dark 10%'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['Dark 5%'],
        border: colors.primary['Dark 10%'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['Dark 5%'],
        border: colors.primary['Dark 10%'],
      },
      disabled: {
        text: colors.primary['Dark 20%'],
        border: colors.primary['Dark 5%'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.primary['Dark 5%'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        border: colors.primary['Dark 10%'],
      },
    },
    tonal: {
      default: {
        text: colors.primary['100'],
        background: colors.primary['Dark 5%'],
      },
      hover: {
        text: colors.primary['100'],
        background: colors.primary['Dark 10%'],
      },
      focused: {
        text: colors.primary['100'],
        background: colors.primary['Dark 10%'],
      },
      disabled: {
        text: colors.primary['Dark 20%'],
        background: colors.primary['Dark 5%'],
      },
      pressed: {
        text: colors.primary['100'],
        background: colors.primary['Dark 20%'],
      },
      loading: {
        text: colors.primary['100'],
        background: colors.primary['Dark 5%'],
      },
    },
    text: {
      default: {
        text: colors.primary['100'],
      },
      hover: {
        text: colors.primary['100'],
        background: colors.primary['Dark 5%'],
      },
      focused: {
        text: colors.primary['100'],
        background: colors.primary['Dark 5%'],
      },
      disabled: {
        text: colors.primary['Dark 20%'],
      },
      pressed: {
        text: colors.primary['100'],
        background: colors.primary['Dark 10%'],
      },
      loading: {
        text: colors.primary['100'],
        background: colors.primary['Dark 5%'],
      },
    },
  },
  accent: {
    filled: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['100'],
          LFP: colors.accent['10'],
        }),
        background: colors.accent['50'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['100'],
          LFP: colors.accent['90'],
        }),
        background: colors.accent['60'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['100'],
          LFP: colors.accent['90'],
        }),
        background: colors.accent['60'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['100'],
          LFP: colors.accent['90'],
        }),
        background: colors.accent['70'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['100'],
          LFP: colors.accent['90'],
        }),
        background: colors.accent['60'],
      },
    },
    tonal: {
      default: {
        text: colors.accent['50'],
        background: colors.accent['5%'],
      },
      hover: {
        text: colors.accent['50'],
        background: colors.accent['10%'],
      },
      focused: {
        text: colors.accent['50'],
        background: colors.accent['10%'],
      },
      disabled: {
        text: colors.accent['20%'],
        background: colors.accent['5%'],
      },
      pressed: {
        text: colors.accent['50'],
        background: colors.accent['20%'],
      },
      loading: {
        text: colors.accent['50'],
        background: colors.accent['10%'],
      },
    },
    outlined: {
      default: {
        text: colors.accent['50'],
        border: colors.accent['5%'],
      },
      hover: {
        text: colors.accent['50'],
        border: colors.accent['10%'],
      },
      focused: {
        text: colors.accent['50'],
        background: colors.accent['5%'],
        border: colors.accent['10%'],
      },
      disabled: {
        text: colors.accent['20%'],
        background: colors.accent['5%'],
      },
      pressed: {
        text: colors.accent['50'],
        background: colors.accent['5%'],
        border: colors.accent['10%'],
      },
      loading: {
        text: colors.accent['50'],
        border: colors.accent['10%'],
      },
    },
    text: {
      default: {
        text: colors.accent['50'],
      },
      hover: {
        text: colors.accent['50'],
        background: colors.accent['5%'],
      },
      focused: {
        text: colors.accent['50'],
        background: colors.accent['5%'],
      },
      disabled: {
        text: colors.accent['20%'],
      },
      pressed: {
        text: colors.accent['50'],
        background: colors.accent['10%'],
      },
      loading: {
        text: colors.accent['50'],
        background: colors.accent['5%'],
      },
    },
  },
  error: {
    filled: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.error['50'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.error['60'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.error['60'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.error['70'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.error['60'],
      },
    },
    tonal: {
      default: {
        text: colors.error['50'],
        background: colors.error['5%'],
      },
      hover: {
        text: colors.error['50'],
        background: colors.error['10%'],
      },
      focused: {
        text: colors.error['50'],
        background: colors.error['10%'],
      },
      disabled: {
        text: colors.error['20%'],
        background: colors.error['5%'],
      },
      pressed: {
        text: colors.error['50'],
        background: colors.error['20%'],
      },
      loading: {
        text: colors.error['50'],
        background: colors.error['10%'],
      },
    },
    outlined: {
      default: {
        text: colors.error['50'],
        border: colors.error['5%'],
      },
      hover: {
        text: colors.error['50'],
        border: colors.error['10%'],
        background: colors.error['5%'],
      },
      focused: {
        text: colors.error['50'],
        border: colors.error['10%'],
        background: colors.error['5%'],
      },
      disabled: {
        text: colors.error['20%'],
        border: colors.error['20%'],
      },
      pressed: {
        text: colors.error['50'],
        background: colors.error['10%'],
        border: colors.error['10%'],
      },
      loading: {
        text: colors.error['50'],
        border: colors.error['30%'],
      },
    },
    text: {
      default: {
        text: colors.error['50'],
      },
      hover: {
        text: colors.error['50'],
        background: colors.error['5%'],
      },
      focused: {
        text: colors.error['50'],
        background: colors.error['5%'],
      },
      disabled: {
        text: colors.error['20%'],
      },
      pressed: {
        text: colors.error['50'],
        background: colors.error['10%'],
      },
      loading: {
        text: colors.error['50'],
        background: colors.error['5%'],
      },
    },
  },
  warning: {
    filled: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.warning['50'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.warning['60'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.warning['60'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.warning['70'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.warning['60'],
      },
    },
    tonal: {
      default: {
        text: colors.warning['50'],
        background: colors.warning['5%'],
      },
      hover: {
        text: colors.warning['50'],
        background: colors.warning['10%'],
      },
      focused: {
        text: colors.warning['50'],
        background: colors.warning['10%'],
      },
      disabled: {
        text: colors.warning['20%'],
        background: colors.warning['5%'],
      },
      pressed: {
        text: colors.warning['50'],
        background: colors.warning['20%'],
      },
      loading: {
        text: colors.warning['50'],
        background: colors.warning['10%'],
      },
    },
    outlined: {
      default: {
        text: colors.warning['50'],
        border: colors.warning['5%'],
      },
      hover: {
        text: colors.warning['50'],
        border: colors.warning['10%'],
        background: colors.warning['5%'],
      },
      focused: {
        text: colors.warning['50'],
        border: colors.warning['10%'],
        background: colors.warning['5%'],
      },
      disabled: {
        text: colors.warning['20%'],
        border: colors.warning['20%'],
      },
      pressed: {
        text: colors.warning['50'],
        background: colors.warning['10%'],
        border: colors.warning['10%'],
      },
      loading: {
        text: colors.warning['50'],
        border: colors.warning['30%'],
      },
    },
    text: {
      default: {
        text: colors.warning['50'],
      },
      hover: {
        text: colors.warning['50'],
        background: colors.warning['5%'],
      },
      focused: {
        text: colors.warning['50'],
        background: colors.warning['5%'],
      },
      disabled: {
        text: colors.warning['20%'],
      },
      pressed: {
        text: colors.warning['50'],
        background: colors.warning['10%'],
      },
      loading: {
        text: colors.warning['50'],
        background: colors.warning['5%'],
      },
    },
  },
  info: {
    filled: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.info['50'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.info['60'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.info['60'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.info['70'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.info['60'],
      },
    },
    tonal: {
      default: {
        text: colors.info['50'],
        background: colors.info['5%'],
      },
      hover: {
        text: colors.info['50'],
        background: colors.info['10%'],
      },
      focused: {
        text: colors.info['50'],
        background: colors.info['10%'],
      },
      disabled: {
        text: colors.info['20%'],
        background: colors.info['5%'],
      },
      pressed: {
        text: colors.info['50'],
        background: colors.info['20%'],
      },
      loading: {
        text: colors.info['50'],
        background: colors.info['10%'],
      },
    },
    outlined: {
      default: {
        text: colors.info['50'],
        border: colors.info['5%'],
      },
      hover: {
        text: colors.info['50'],
        border: colors.info['10%'],
        background: colors.info['5%'],
      },
      focused: {
        text: colors.info['50'],
        border: colors.info['10%'],
        background: colors.info['5%'],
      },
      disabled: {
        text: colors.info['20%'],
        border: colors.info['20%'],
      },
      pressed: {
        text: colors.info['50'],
        background: colors.info['10%'],
        border: colors.info['10%'],
      },
      loading: {
        text: colors.info['50'],
        border: colors.info['30%'],
      },
    },
    text: {
      default: {
        text: colors.info['50'],
      },
      hover: {
        text: colors.info['50'],
        background: colors.info['5%'],
      },
      focused: {
        text: colors.info['50'],
        background: colors.info['5%'],
      },
      disabled: {
        text: colors.info['20%'],
      },
      pressed: {
        text: colors.info['50'],
        background: colors.info['10%'],
      },
      loading: {
        text: colors.info['50'],
        background: colors.info['5%'],
      },
    },
  },
  success: {
    filled: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.success['50'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.success['60'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.success['60'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.success['70'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.success['60'],
      },
    },
    tonal: {
      default: {
        text: colors.success['50'],
        background: colors.success['5%'],
      },
      hover: {
        text: colors.success['50'],
        background: colors.success['10%'],
      },
      focused: {
        text: colors.success['50'],
        background: colors.success['10%'],
      },
      disabled: {
        text: colors.success['20%'],
        background: colors.success['5%'],
      },
      pressed: {
        text: colors.success['50'],
        background: colors.success['20%'],
      },
      loading: {
        text: colors.success['50'],
        background: colors.success['10%'],
      },
    },
    outlined: {
      default: {
        text: colors.success['50'],
        border: colors.success['5%'],
      },
      hover: {
        text: colors.success['50'],
        border: colors.success['10%'],
        background: colors.success['5%'],
      },
      focused: {
        text: colors.success['50'],
        border: colors.success['10%'],
        background: colors.success['5%'],
      },
      disabled: {
        text: colors.success['20%'],
        border: colors.success['20%'],
      },
      pressed: {
        text: colors.success['50'],
        background: colors.success['10%'],
        border: colors.success['10%'],
      },
      loading: {
        text: colors.success['50'],
        border: colors.success['30%'],
      },
    },
    text: {
      default: {
        text: colors.success['50'],
      },
      hover: {
        text: colors.success['50'],
        background: colors.success['5%'],
      },
      focused: {
        text: colors.success['50'],
        background: colors.success['5%'],
      },
      disabled: {
        text: colors.success['20%'],
      },
      pressed: {
        text: colors.success['50'],
        background: colors.success['10%'],
      },
      loading: {
        text: colors.success['50'],
        background: colors.success['5%'],
      },
    },
  },
  virtual: {
    filled: {
      default: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.virtual['50'],
      },
      hover: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.virtual['60'],
      },
      focused: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.virtual['60'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.virtual['70'],
      },
      loading: {
        text: Brand.switchBrand({
          UC: colors.primary['0'],
          LFP: colors.accent['10'],
        }),
        background: colors.virtual['60'],
      },
    },
    tonal: {
      default: {
        text: colors.virtual['50'],
        background: colors.virtual['5%'],
      },
      hover: {
        text: colors.virtual['50'],
        background: colors.virtual['10%'],
      },
      focused: {
        text: colors.virtual['50'],
        background: colors.virtual['10%'],
      },
      disabled: {
        text: colors.virtual['20%'],
        background: colors.virtual['5%'],
      },
      pressed: {
        text: colors.virtual['50'],
        background: colors.virtual['20%'],
      },
      loading: {
        text: colors.virtual['50'],
        background: colors.virtual['10%'],
      },
    },
    outlined: {
      default: {
        text: colors.virtual['50'],
        border: colors.virtual['5%'],
      },
      hover: {
        text: colors.virtual['50'],
        border: colors.virtual['10%'],
        background: colors.virtual['5%'],
      },
      focused: {
        text: colors.virtual['50'],
        border: colors.virtual['10%'],
        background: colors.virtual['5%'],
      },
      disabled: {
        text: colors.virtual['20%'],
        border: colors.virtual['20%'],
      },
      pressed: {
        text: colors.virtual['50'],
        background: colors.virtual['10%'],
        border: colors.virtual['10%'],
      },
      loading: {
        text: colors.virtual['50'],
        border: colors.virtual['30%'],
      },
    },
    text: {
      default: {
        text: colors.virtual['50'],
      },
      hover: {
        text: colors.virtual['50'],
        background: colors.virtual['5%'],
      },
      focused: {
        text: colors.virtual['50'],
        background: colors.virtual['5%'],
      },
      disabled: {
        text: colors.virtual['20%'],
      },
      pressed: {
        text: colors.virtual['50'],
        background: colors.virtual['10%'],
      },
      loading: {
        text: colors.virtual['50'],
        background: colors.virtual['5%'],
      },
    },
  },
});
